.wrapper {
  @media (min-width: 576px) {
    background-position: center -30px;
    background-size: 1064px 770px;
  }

  @media (max-width: 576px) {
    background-position: center 550px;
    background-size: 574px 1000px;
  }
}
