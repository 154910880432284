.wrapper {
  border: 2px solid white;
  background-position: -660px 0px;
  background-size: 1100px 658px;
  box-shadow: 0 6px 25px #e9e4e4;
  @apply mx-lg box-border bg-no-repeat w-full max-w-[1170px];
  @screen xs {
    background-position: 500px -200px;
    background-size: 1100px 658px;
  }
}
